.copy-container{
	width:95%;
	margin:0 auto;
	position: relative;
	min-height:32px;
	padding-bottom: 0px;
	position: relative;
  overflow: hidden;
  height: 1%;
}
.sectionContainer.copy{
	padding-top: 0px;
	padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
}
/*.copy{
	width:87%;
	margin:0 auto;
	position: relative;
}*/
.previous{
	background:url('images/nav-left.png')center no-repeat;
	width:33px; height:28px;
	position: absolute;
/*	left:28%;*/
	cursor:pointer;
	display:table-cell;vertical-align:middle;
	top: 117px;
	 left: 50%;	
  margin-left: -190px;
/*	bottom:-20px;*/

@media all and (max-width:400px){
	left: 66%;
}
}
.next{
	background:url('images/nav-right.png')center no-repeat;
	width:33px; height:28px;
	position: absolute;
	 left: 50%;	
  margin-left: 153px;	
/*	right: 28%;*/
	cursor:pointer;
	top: 117px;
/*	display:table-cell;vertical-align:middle;*/
/*	bottom:-20px;*/
@media all and (max-width:400px){
	left: 35%;
}
}
.testimonial-circles{
	background:url('images/circles.png')center no-repeat;
	width:50px; height:28px;
	position: absolute;
	left:20px;
	cursor:pointer;
	bottom:-20px;
}
.slide-test{
	position: absolute;
	top: 0;
	left: 100%;
	width:100%;
  font-family: 'Roboto Slab', serif;
  font-size: 17px;
  line-height: 28px;
  color: $color_white;
 
  p{
	 
	  color: $color_white;
	  padding-bottom: 20px;
  }
  &.current {
		left: 0;
		-webkit-transition: all 0.3s ease;
		transition: all 0.3s ease;
	}

	&.prev-left {
		left: -100%;
		-webkit-transition: all 0.3s ease;
		transition: all 0.3s ease;
	}
	&.prev-right {
		left: 100%;
		-webkit-transition: all 0.3s ease;
		transition: all 0.3s ease;
	}
	&.before-left {
		left: -100%;
	}
}
.testimonial-image{
	
}
@-webkit-keyframes bounceIn { 
    0% { 
        opacity: 0; 
        -webkit-transform: scale(.3); 
    } 

    50% { 
        opacity: 1; 
        -webkit-transform: scale(1.0); 
    } 

    70% { 
        -webkit-transform: scale(1); 
    } 

    100% { 
         -webkit-transform: scale(1); 
    } 
} 

@keyframes bounceIn { 
    0% { 
        opacity: 0; 
        transform: scale(.3); 
    } 

    50% { 
        opacity: 1; 
        transform: scale(1.05); 
    } 

    70% { 
        transform: scale(.9); 
    } 

    100% { 
        transform: scale(1); 
    } 
} 
.testimonial{
	  color: $color_white;
  font-size: 18px;
  text-transform: none;
  letter-spacing: 0;
  padding-left: 27px;
  padding-right: 27px;
  padding-top: 0px;

  line-height: 28px;
    border: 11px solid $color_teal;
	background-color:$color_teal;
	position: relative;
	overflow:hidden;
height:1%;
  padding: 20px 12px 0 28px;

}
@-webkit-keyframes fadeIn { 
    0% {opacity: 0;} 
    100% {opacity: 1;} 
} 
@keyframes fadeIn { 
    0% {opacity: 0;} 
    100% {opacity: 1;} 
}

p.testimonial-title{
	font-size: 30px;
	line-height: 40px;
	font-family:'Montserrat', sans-serif;
	text-align: center;
	display: block;
	margin-top: 30px;
	text-transform: initial;
	 -webkit-animation-duration: .5s; 
    animation-duration: .5s; 
    -webkit-animation-fill-mode: both; 
    animation-fill-mode: both;
	 -webkit-animation-name: fadeIn; 
    animation-name: fadeIn;
}
p.testimonial-quote{
	font-family: 'Muli', sans-serif;
	text-align: center;
	font-size: 16px;
	line-height: 29px;
	display: block;
	  margin-top: -23px;
	    margin-bottom: -8px;
		 -webkit-animation-duration: .5s; 
    animation-duration: .5s; 
    -webkit-animation-fill-mode: both; 
    animation-fill-mode: both;
	 -webkit-animation-name: fadeIn; 
    animation-name: fadeIn;
}

img.circle-pic{
		-webkit-animation-duration: .5s; 
    animation-duration: .5s; 
    -webkit-animation-fill-mode: both; 
    animation-fill-mode: both;
	-webkit-animation-name: bounceIn; 
    animation-name: bounceIn; 
	animation-delay: .5s;
	background-position:center top; 
	background-repeat: no-repeat;
	float: none;
	display: block;
    margin-left: auto;
    margin-right: auto;
	}
	@media screen and (max-width: 750px){
		p.testimonial-quote{
	font-size: 14px;
	line-height: 24px;
}
p.testimonial-author{
	font-size: 14px;
	line-height: 29px;

}
p.testimonial-title{
	font-size: 22px;
	line-height: 32px;
}
	}
@media all and (max-width:600px){
	
/*	.next, .previous{
		top: 85px;
	}*/
}