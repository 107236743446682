/*--------------------------------------------------------------------*/
/*	MOBILE NAV
/*--------------------------------------------------------------------*/
body.showMobileNav {
	overflow: hidden;
}
#mobileNav {
	display: none;
	width: 270px;
	  max-width: 270px;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(1,154,149,.95);
	font-family: $font-heading;
	text-transform: uppercase;
	z-index: 20;
	@include transform-stack(translate(270px, 0));
	@include transition-stack(all 300ms ease);
}
body.showMobileNav #mobileNav {
	@include transform-stack(translate(0, 0));
}

.dropdown ul{
	width: 230px;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	background-color: $color_blue;
	text-transform: uppercase;
	z-index: 2000;
	-webkit-transform: translate(230px, 0);
	    -ms-transform: translate(230px, 0);
	        transform: translate(230px, 0);
	-webkit-transition: all 0.3s ease;
	        transition: all 0.3s ease;
}
body.showMobileNav #mobileNav {
	-webkit-transform: translate(0, 0);
	    -ms-transform: translate(0, 0);
	        transform: translate(0, 0);
}
body.showMobileNav2 .dropdown ul {
	-webkit-transform: translate(0, 0);
	    -ms-transform: translate(0, 0);
	        transform: translate(0, 0);
}
#mobileNav-tab {
	position: absolute;
	top: 0;
	left: -60px;
	height: 59px;
	width: 60px;
	padding: 9px;
	color: $color_teal;
	background-color: $color_black;
	font-size: 10px;
	font-weight: 100;
	letter-spacing: 0.3px;
	line-height: 15px;
	text-transform: uppercase;
	@include transition-stack(all 300ms ease);
}
body.showMobileNav #mobileNav-tab {
	line-height: 12px;
	color: $color_white;
	background-color: $color_teal;
}
#mobileNav-tab {
	text-align: center;
	@include transform-stack(translate(0, 0));
	@include transition-stack(all 200ms ease-in-out);
}
#mobileNav-tab:before {
	content: '';
	position: absolute;
	bottom: 22px;
	left: 14px;
	right: 14px;
	height: 4px;
	border-top: $color_teal solid 4px;
	border-bottom: $color_teal solid 4px;
	@include transition-stack(all 200ms ease-in-out);
}
#mobileNav-tab:after {
	content: '';
	position: absolute;
	bottom: 14px;
	left: 14px;
	right: 14px;
	border-bottom: $color_teal solid 4px;
	@include transition-stack(all 200ms ease-in-out);
}
body.showMobileNav #mobileNav-tab:before {
	bottom: 17px; 
	left: 13px;
	right: 14px;
	height: 4px;
	border-top: $color_white solid 4px;
	border-bottom: none;
	@include transform-stack(rotate(45deg));
}
body.showMobileNav #mobileNav-tab:after {
	bottom: 21px; 
	left: 14px;
	right: 13px;
	border-bottom: $color_white solid 4px;
	@include transform-stack(rotate(-45deg));
}
#mobileNav-menu {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	  overflow: auto;
/*	overflow-y: auto;*/
	padding: 24px 2px;
	width: 266px;
}
.mobileNavGroup {
	margin-bottom: 50px;
	text-align: left;
}
.mobileNavGroup ul {
	list-style: none;
	padding-left: 0;
}
.mobileNavGroup li {
	padding: 0px 18px;
	margin: 0;
	border-bottom: 1px solid $color_dk-grey-2;
}
.mobileNavGroup li a {
	display: block;
	margin: 0;
	color: $color_white;
	line-height: 40px;
	font-size: 15px;
	letter-spacing: .1em;
	font-family: 'Muli', sans-serif;
}
.mobileNavGroupTitle {
	padding-left: 18px;
	font-size: 12px;
	color: #cacaca;
	font-weight: 300;
	margin-bottom: 10px;
}
.mobileNavGroup .phone {
	-webkit-hyphens: none;
}
.mobile-overlay{
	display:none;
	position: fixed;
	left:0; top:0;
	right:0; bottom:0;
	background:rgba(0,0,0,.7);
	z-index:10;
}
.mobile-overlay.display{display:block;}