/* -------------------------------------------------------------------------- */
/* Responsive */
/* -------------------------------------------------------------------------- */
@media screen and (min-width: 1300px){
	#slideshow .home-h1-subtitle{
		left: 535px;
	}
}
@media screen and (max-width: 1000px){
	#slideshow h1, #slideshow .home-h1-subtitle, #slide-nav{
		left: 0;
		margin-left: 50px;
	}
	.header-logo{
		width:340px;
		height:60px;
		margin-top: 13px;
	}
	.testimonial{
		padding:0px 12px 0 28px;
	}
}
@media screen and (max-width: 980px){
	span.nav-hide{
		display: none;
	}
}
@media screen and (max-width: 920px){
	.home-consign a {
		font-size: 16px;
	}
	.home-consult .title {
		font-size: 16px;
	}
	.hover-cover {
		padding: 27px 8.57143%;
	}
	.hover-cover .copy {
		font-size: 17px;
		line-height: 27px;
	}
	.nav-icon {
		margin: 0 32px 0 0;
	}
}
@media screen and (max-width: 850px){
	.testimonial {
  line-height: 24px;
}
	.upper-nav{
		position: absolute;
		right:7px;
		top:28px;
	}
	.show-on-tablet {
		display:block;
	}
	.hide-on-tablet {
		display:none;
	}
	header{
		nav ul {
			max-width:720px;
/*			margin: 0 auto;*/
		}
		nav li a {
			font-size:13px;
			padding:10px 10px;
		}
		.header-logo{margin-top:13px;   
					  
					}
	} 
	#slideshow h1 {
		font-size: 45px;
		line-height: 48px;
	}
	#slideshow .home-h1-subtitle {
		font-size: 24px;
		line-height: 30px;
/*		top:22%;*/
	}
	.nav-icon {
		margin: 0 20px 0 0;
	}
	.home-appraise{
		.play-button{
			font-size:13px;
			width:126px;
			height:28px;
			line-height: 27px;
			margin-left:-63px;
		}
	}
	.home-consult{
		.title{
			font-size:14px;
			height:31px;
			line-height:31px;
		}
		.copy{
			padding:40px 14px;
			line-height:27px;
		}
	}
/*	.slide-test{
		top:40px;
	}*/
	.cta{
		  font-size: 17px;
  padding-left: 16px;
  padding: 3px 30px 1px 16px;
	}
}
@media screen and (max-width: 830px){
	
}
@media screen and (max-width: 800px){
	.rowTitle {
		margin-top: 140px;
		position: relative;
	}
	.rowTitle .col-right {
		position: absolute;
		top: -140px;
		left: 0;
		right: 0;
		bottom: auto;
		margin: 0 auto;
		width: 100%;
	}
	.rowTitle .col-left, .about .rowTitle .col-left {
		width: 100%;
		margin-right: 0;
	}
	.rowContent .col-left, .about .rowContent .col-left {
		width: 100%;
		margin-right: 0px;
	}
	.rowContent .col-right, .about .rowContent .col-right {
		width: 100%;
	}
}
@media screen and (max-width: 780px){
	.show-at-780 {
		display: block;
	}
	.home-consign a {
		top: auto;
		bottom: -54px;
		height: 54px;
		line-height: 20px;
		padding-top: 7px;
	}
	.nav-social{display: none;}
}
@media screen and (max-width: 768px){
	.xray-img{
		  max-width: 200px;
	}
	.phone .phoneLink {
		height: 100%;
	}
	.header-logo{margin-top:13px;   
					  width: 275px;
  height: 43px;
					}
	.headerContainer,nav {
		padding: 0 30px;
	}
	header .upper-nav{
		padding: 0 20px;
	}
	.social a{
		margin-right:4px;
	}
	header nav ul {
		max-width:590px;
	}
	header nav li a {
		font-size:12px;
		padding:10px 7px;
	}
	
	.testimonial{
		padding:15px 12px 0 15px;
	}
	#slideshow h1 {
		font-size: 38px;
		line-height: 42px;
	}
	#slideshow .home-h1-subtitle {
		font-size: 20px;
		line-height: 27px;
	}
	#slideshow h1, #slideshow .home-h1-subtitle, #slide-nav{
		margin-left: 30px;
	}
	.buylist .rowContent ul {
		-webkit-column-count: 2;
		   -moz-column-count: 2;
				column-count: 2;
	}
	.home-appraise{
		.play-button{
			font-size:12px;
			width:110px;
			height:25px;
			line-height: 24px;
			margin-left:-55px;
			bottom:14%;
		}
	}
	.home-consult .copy{
		font-size:15px;
		line-height:22px;
	}
	.home-learn .more, .home-learn .shown-read-more{font-size:13px;}
}
@media screen and (max-width: 750px){
	.sectionContainer img.matt{ width: 47%;   padding-left: 15px;}
	.logo-small, .logo-mobile{
		  margin: 5px 0 0 0;
	}
	#slideshow, #googleMap {
		height: 380px;
	}
	.slider-pic{
		display: none;
	}
	.slider-logo{
		width: 324px;
  height: 118px;
		top: -130px;
	}
	#slideshow .home-h1-subtitle{
		top: 175px;
	}
}
@media screen and (max-width: 710px){
	.show-at-710 {
		display: block;
	}
	.home-consult .title {
		line-height: 20px;
		padding-top: 7px;
	}
	.hover-cover {
		padding: 12px 8.57143%;
	}
	.hover-cover .copy {
		font-size: 16px;
		line-height: 24px;
	}
	.home-appraise{
		a{margin:0 2px;}
	}
	.sectionContainer{padding-right:50px;}
	.testimonial{line-height:20px;}
	.nav-social{
		display: none;
	}
}
@media screen and (max-width: 630px){
	.xray-img{
		  max-width: 150px;
	}
}
@media screen and (max-width: 650px) {
	.contact-form form input.field.email{
		width: 100%;
	}
	.cta-side{
		display: none;
	}

        .mobile-show{
            display: block;
        }
	#phone{
		position: fixed;
		color: $color_white;
		background: $color_teal;
		top: 59px;
		width: 100%;
		text-align:center;
		z-index: 5;
		border: 1px solid $color_white;
		a{
			color: $color_white;
			text-align: center;
		}
	}
        #schedule{
		position: fixed;
		background: $color_black;
		top: 90px;
		width: 100%;
		text-align:center;
		z-index: 5;
		border: 1px solid $color_white;
                
		a{
			color: $color_white;
			text-align: center;
		}
	}
        
	.mobile-logo-new{
	display: block;
	  top: 4px;
  left: 5px;


}
	.slide-text-position{display: none;}
	.upper-nav{display:none;}
	#video-pop{margin-top:18%;}
	.show-on-mobile{
		display:block;
	}
	.hide-on-mobile{
		display:none;
	}
	header nav {
		display: none;
	}
	#mobileNav {
		display: block;
		overflow: initial;
	}
	header {
		height: 59px;
		
		.headerContainer {
			padding: 0px 17px;
		}
		.header-logo {
			width:200px;
			height: 48px;
			padding: 12px 0 0 0;
			margin-top: 8px;
		}
	}
	main {
		padding-top: 30px;
	}
	.contact-pop{
		top:60px;
		width:300px;
		margin-left:-150px;
		.contact-container{padding:0 10px;}
		.header-logo{margin-bottom:10px;}
		.image{
			width:50px;
			height:50px;
			margin-right:12px;
		}
		.contact-text{
			width:208px;
			margin-bottom:10px;
		}
	}
	.home #slideshow {
		height: 100%;
		padding-top: 51.25%;
/*		margin-bottom: 130px;*/
		overflow: visible;
	}
	#slideshow {
		height: 100%;
		padding-top: 51.25%;
	}
	#slideshow h1 {
		font-size: 27px;
		line-height: 31px;
		color: #000;
		top: auto;
		bottom: -67px;
		text-shadow: none;
	}
	#slideshow .home-h1-subtitle {
		font-size: 21px;
		line-height: 24px;
		color: #000;
		top: auto;
		bottom: -119px;
		text-shadow: none;
	}
	#slide-nav {
		bottom: 12px;
	}
	#slide-nav .radio-wrap {
		width: 18px;
		height: 18px;
	}
	#slideshow h1, #slideshow .home-h1-subtitle, #slide-nav{
		margin-left: 0px;
	}
	#slideshow .home-h1-subtitle{
		max-width: 0px;
	}
	.home .row {
		margin-bottom: 0;
	}
	.home .row1 .col-left {
		width: 100%;
		margin-right: 0;
		margin-bottom: 20px;
	}
	.home .row1 .col-right {
		width: 100%;
		margin-bottom: 20px;
	}
	.home .row2 .col-left {
		width: 100%;
		margin-right: 0;
		margin-bottom: 20px;
	}
	.home .row2 .col-right {
		width: 100%;
		margin-bottom: 20px;
	}
	.home-consign a {
		top: 50%;
		bottom: auto;
		height: 54px;
		line-height: 54px;
		padding-top: 0;
		font-size: 19px;
	}
	.home-consult {
		.copy{padding:74px 32px;}
		.title {
			line-height: 54px;
			padding-top: 0;
			font-size: 19px;
			height:54px;
		}
	}
	.social-connect{display:none;}
	.show-at-710, .show-at-780 {
		display: none;
	}
	.hover-cover {
		padding: 40px 8.57143%;
	}
	.hover-cover .copy {
		font-size: 20px;
		line-height: 32px;
	}
	.home .row2 {
		height: auto;
	}
	.footer-logo {
		width: 216px;
		height: 52px;
		margin: 0 auto 20px;
	}
	.social {
		margin: 0 auto 10px;
		float: none;
	}
	#newsletter-form {
		margin-bottom: 50px;
	}
	#newsletter-form form input {
		width: 100%;
		margin-bottom: 12px;
	}
	#newsletter-form form input[type="submit"] {
		width: auto;
		padding: 14px 27px;
		display: block;
	}
	#newsletter-form .thank div {
		width: 100%;
		float: left;
		border-right: none;
		box-sizing: border-box;
		padding-top: 30px;
	}
	#newsletter-form .thank-close {
		display: inline-block;
		width: auto;
		float: none;
		height: auto;
		line-height: 15px;
		margin: 0;
		padding: 10px 30px;
		margin: 0 auto;
		text-align: center;
		box-sizing: border-box;
	}
	#footer-sitemap {
		margin-bottom: 15px;
	}
	#footer-sitemap ul li {
		display: block;
		border: none;
		line-height: 30px;
		text-align: left;
		font-size: 14px;
		padding: 0;
		margin: 0;
	}
	#footer-sitemap ul li a {
		display: block;
		border: none;
		line-height: 30px;
		text-align: left;
		font-size: 14px;
		padding: 0;
		margin: 0;
	}
/*	#footer-sitemap ul li:first-of-type {
		text-transform: uppercase;
	}
	#footer-sitemap ul li:first-of-type:after {
		content: '';
		width: 50px;
		height: 1px;
		background-color: $color_dk-grey-2;
		margin: 10px 0;
		display: block;
	}*/
	#footer-legal {
		text-align: left;
		line-height: 30px;
		font-size:11px;
		.footer-address{line-height:10px;}
	}
	#footer-legal a svg {
		left: 0px;
		margin-left:0;
	}
	.col-66{float:none; width:100%;}
	.sectionContainer{padding:30px 20px;}
	.home-consult .copy{
		font-size:19px;
		line-height:27px;
	}
	.slide-test{
	padding-top: 0px;
	}
	.col-33.full-width{ width: 100%;	}
	#googleMap{height:200px;}
	.contact-form{
	form{
		input, select{
			width:100%;
			margin-right: 0%;
		}
		textarea{
			width:100%;
			background-color:$color_white;
			border:1px solid $color_dk-grey-2;
			
		}
	}
	}
	#footer-company p{
		font-size: 20px;
	}

	#slideshow .get-started{
		display: none;
	}
	#nav-cta{
		height: 59px;
	}
	.video-section{
		width: 100%;
	}
}
@media screen and (max-width: 520px){
	.home .sectionContainer {
		padding: 35px 30px;
	}
	.home-consign a {
		font-size: 16px;
	}
	.home-consult .title {
		font-size: 16px;
	}
	.hover-cover {
		padding: 27px 8.57143%;
	}
	.hover-cover .copy {
		font-size: 17px;
		line-height: 27px;
	}
}
@media screen and (max-width: 480px){
	.show-at-780 {
		display: block;
	}
	.home-consign a {
		
		height: 54px;
		line-height: 20px;
		padding-top: 7px;
	}
	.xray-img{
		  max-width: 200px;
	}
	p.xray-desc{
		margin-bottom: 0px; 
  padding-bottom: 60px;
	}
	.next-xray, .previous-xray{
		top: 250px;
	}
}

@media screen and (max-width: 410px){
	.show-at-710 {
		display: block;
	}
	.home-consult .title {
		line-height: 40px;
		padding-top: 7px;
	}
	.hover-cover {
		padding: 12px 8.57143%;
	}
	.hover-cover .copy {
		font-size: 16px;
		line-height: 24px;
	}
}
@media screen and (max-width: 400px) {

	.social-footer .silver-creek{margin-right:4px;}
	#slideshow h1 {
		font-size: 20px;
		line-height: 24px;
		bottom: -57px;
	}

	.home-consult .copy{
		font-size:16px;
		line-height:24px;
	}
	.home-learn .more, .home-learn .shown-read-more{font-size:11px;}
	.contact-pop{
		height:500px;
		max-height:110%;
	}
	#footer-company p{
		font-size: 13px;
	}

	.home-appraise .imageWrap{
		  margin-bottom: -30px;
	}
}