.xray-container{
	width:95%;
	margin:0 auto;
	position: relative;
	padding: 35px;	
	 margin-top: 10px;

}
.sectionContainer.xray{
	padding: 0;
	  overflow: hidden;
}
/*.copy{
	width:87%;
	margin:0 auto;
	position: relative;
}*/
.previous-xray{
	background:url('images/nav-left.png')center no-repeat;
	width:33px; height:28px;
	position: absolute;
	left:50%;
	margin-left: -330px;
	cursor:pointer;
	display:table-cell;vertical-align:middle;
	top: 250px;
	/*	bottom:-20px;*/
}
.next-xray{
	background:url('images/nav-right.png')center no-repeat;
	width:33px; height:28px;
	position: absolute;
	/*	right: 0%;*/
	left: 50%;
	margin-left: 290px;
	cursor:pointer;
	top: 250px;
	display:table-cell;vertical-align:middle;
	/*	bottom:-20px;*/
}
.testimonial-circles{
	background:url('images/circles.png')center no-repeat;
	width:50px; height:28px;
	position: absolute;
	left:20px;
	cursor:pointer;
	bottom:-20px;
}
.xray-test{
	position: absolute;
	top: 0;
	left: 100%;
	width:100%;
	font-family: 'Roboto Slab', serif;
	font-size: 17px;
	line-height: 28px;
	color: $color_white;
	text-align: center;

	p{

		color: $color_white;
		padding-bottom: 20px;
	}
	&.current {
		left: 0;
		-webkit-transition: all 0.3s ease;
		transition: all 0.3s ease;
		opacity: 1;
		
	}

	&.prev-left {
		left: -100%;
		-webkit-transition: all 0.3s ease;
		transition: all 0.3s ease;
		opacity: 0;
	}
	&.prev-right {
		left: 100%;
		-webkit-transition: all 0.3s ease;
		transition: all 0.3s ease;
		opacity: 0;
	}
	&.before-left {
		left: -100%;
		opacity: 0;
	}
}


@-webkit-keyframes bounceIn { 
    0% { 
        opacity: 0; 
        -webkit-transform: scale(.3); 
    } 

    50% { 
        opacity: 1; 
        -webkit-transform: scale(1.05); 
    } 

    70% { 
        -webkit-transform: scale(.9); 
    } 

    100% { 
		-webkit-transform: scale(1); 
    } 
} 

@keyframes bounceIn { 
    0% { 
        opacity: 0; 
        transform: scale(.3); 
    } 

    50% { 
        opacity: 1; 
        transform: scale(1.05); 
    } 

    70% { 
        transform: scale(.9); 
    } 

    100% { 
        transform: scale(1); 
    } 
} 
.xray-section{
	color: $color_white;
	font-size: 18px;
	text-transform: none;
	letter-spacing: 0;
	line-height: 28px;
	background-color:#000;
	position: relative;
	overflow:hidden;
	height:1%;
	.sectionContainer{
		h5{text-align:center;}
		p{color:$color_white;}
	}

}
p.xray-title{
	font-size: 23px;
	line-height: 40px;
	font-family:'Montserrat', sans-serif;
	text-align: center;
	display: block;
	margin-top: -50px;
	color: $color_grayDark !important;
	text-transform: uppercase;
	font-weight: 700;
}
p.xray-desc{
	font-family: 'Muli', sans-serif;
	text-align: center;
	font-size: 16px;
	line-height: 23px;
	display: block;
	margin-top: 385px;
	margin-bottom: -8px;
	 padding-left: 15px;
  padding-right: 15px;

}
p.xray-patient{
	font-family:'Montserrat', sans-serif;
	text-align: center;
	font-size: 16px;
	line-height: 22px;
	letter-spacing: .1em;
	text-transform: uppercase;
	color: $color_grayDark;
	font-weight: 700;
	span{
		color: $color_white;
	}
}
.xray-img{
	max-width: 260px;
	display: inline-block;
	position: relative;
	vertical-align: text-top;
	background-position: center top;
	background-repeat: no-repeat;
	/*	  margin-left: 5%;*/

}
.xray-img.left, .xray-img.right{
	-webkit-animation-duration: .5s; 
    animation-duration: .5s; 
    -webkit-animation-fill-mode: both; 
    animation-fill-mode: both; 
	-webkit-animation-name: fadeIn; 
    animation-name: fadeIn; 
}
@-webkit-keyframes fadeIn { 
    0% {opacity: 0;} 
    100% {opacity: 1;} 
} 
@keyframes fadeIn { 
    0% {opacity: 0;} 
    100% {opacity: 1;} 
} 
.xray-center{
	width: 100%;
	margin: 0 auto;
	display: inline-block;
}

@media all and (max-width:600px){
	.xray-container{
		display: none;
	}
	.slide-test{
		width: 70%; 
		font-size: 14px;
		line-height: 25px;
	}
}