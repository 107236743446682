/* -------------------------------------------------------------------------- */
/* Footer */
/* -------------------------------------------------------------------------- */
footer{
	background: $color_grayDark;
	  overflow: auto;
}
.footerContainer {
    max-width: 877px;
    margin: 0 auto;
	padding: 16px 20px 36px;
	text-align: center;
}
#footer-company {
	margin-bottom: 18px;
}
#footer-company p {
	font-size: 15px;
	font-family: $font-heading;
	line-height: 25px;
	color: $color_white;
	letter-spacing: .1em;
	margin:0 auto 21px;	

	a{
			font-size: 15px;
	font-family: $font-heading;
	text-transform: uppercase;
	line-height: 25px;
	color: $color_white;
	letter-spacing: .1em;
	max-width:446px;
	margin:0 auto 21px;
	text-decoration: none;
	cursor: pointer;
	}
	a:hover{ text-decoration: underline;}

}

// #footer-company p:nth-of-type(2) {
// 	font-size: 52px;
// 	font-family: $font-heading;
// 	text-transform: uppercase;
// 	line-height: 32px;
// 	color: $color_green;
// 	letter-spacing: .05em;
// 	max-width:446px;
// 	margin:0 auto 23px;
// }
.footer-logo {
	width: 301px;
	margin: 0 auto -15px;
}
.footer-schedule{
	background: rgba(255,255,255,0.2);
	max-width: 560px;
	/*	padding-left: 10px;
		padding-right: 10px;*/
	padding: 10px;
	height: 1%;
	overflow: auto;


	margin: 0 auto;
	a{
		color: $color_white;
	}
	a.footer-phone{
		background: $color_blue;
		font-family: 'Montserrat', sans-serif;
		font-size: 22px;
		line-height: 23px;
		border: 2px solid #000;
		padding-top: 10px;
		padding-bottom: 9px;
		float: left;
		padding-left: 15px;
		padding-right: 15px;
		letter-spacing: .025em;
		margin-bottom: 0px;
	}
	a.footer-appointment{
		background: #000;
		font-family: 'Montserrat', sans-serif;
		font-size: 20px;
		line-height: 23px;
		border: 2px solid #000;
		padding-top: 10px;
		padding-bottom: 9px;
		float: left;
		margin-right: 10px;
		padding-left: 17px;
		padding-right: 20px;
		margin-bottom: 0px;
		cursor: pointer;
	}
}
.social-footer{
	.silver-creek{
		width:179px;
		height:31px;
		display:inline-block;
		margin-right:19px;
	}
	a {
		display: inline-block;
		height: 40px;
		width: 40px;
		fill:$color_dk-grey-1;
		text-transform: uppercase;
		margin:0 4px;
	}
	a:hover {
		fill:$color_teal;
	}
}
#newsletter-form-wrap {
	margin-bottom: 24px;
}
#newsletter-form-wrap p {
	font-size: 16px;
	color: $color_dk-grey-2;
	line-height: 24px;
	  margin-bottom: 10px;
}
.footer-contact-title, .footer-social-title {
	padding-left: 15px;
	padding-bottom: 7px;
	border-bottom: solid 1px $color_dk-grey-2;
	margin-bottom: 0px;
}
.footer-contact-intro {
	padding-left: 15px;
	line-height: 39px;
}
#footer-sitemap {
	text-align: center;
	margin-bottom: 7px;
}
#footer-sitemap ul {
	margin-bottom: 6px;
}
#footer-sitemap li {
	display: inline-block;
	padding: 0 1.8%;
	color: $color_white;
	border-left: solid 1px $color_dk-grey-1;
	font-size: 14px;
	line-height: 16px;
}
#footer-sitemap li:first-of-type {
	border-left: none;
}
#footer-sitemap li a {
	font-size: 14px;
	color: $color_white;
	@include transition-stack(all 200ms ease);
}
#footer-sitemap li.current a, 
#footer-sitemap li:hover a {
	color: $color_teal;
}
#footer-legal {
	font-size: 12px;
	line-height:12px;
	color: $color_white;
	.footer-address{
		margin-bottom:20px;
	}
	
}
#footer-legal a {
	color: $color_lt-grey-2;
	position: relative;
}
#footer-legal a svg {
	fill: $color_teal;
	height: 11px;
	width: 26px;
	margin:0 5px;
}
.WTCpower{color:$color_white;}




@media screen and (max-width: 750px){
	.footer-schedule{
		  max-width: 352px;
a.footer-phone{
		font-size: 11px;
		line-height: 15px;
		


	}
	a.footer-appointment{
		font-size: 11px;
		line-height: 15px;
		 


	}
	}
}