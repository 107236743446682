/* colors */
$color_teal: #019a95;
$color_blue: #4d8093;
$color_blueLight: #739ab7;
$color_black:#231f20;
$color_grayDark: #58595b;
$color_gray: #bcbec0;
$color_grayLight: #d0d2d3;








$color_darkGray: #929497;
$color_providers-gray:#808083;



$color_lt-grey-1: #d0d2d3;
$color_lt-grey-2: #bcbec0;
$color_dk-grey-1: #747879;
$color_dk-grey-2: #231f20; /* */
$color_green: #228b22;

$color_white:#fff;
$color_orange: #faaf40;

$color-h1: #019a95;
$color-h1-home: #fff;
$color-h2: #4d8093;
$color-h3: #019a95;
$color-h4: #58595b;
$color-h5: #4d8093;
$color-em: #5EC8E2;
$color-strong: #2ba1c6;
$color-li: #58595b;
$color-li-strong: #58595b;
$color-p: #58595b;
$color-a: #019a95;

/* fonts */
$font-heading:'Muli', sans-serif;
$font-heading2: 'Montserrat', sans-serif;
$font-body: 'Lato', sans-serif;
$font-emph: 'Open Sans' sans-serif;
$font-muli: 'Muli', sans-serif;
$font-paragraph: 'Muli', sans-serif;
$font-form: 'Muli', sans-serif;

/* common mixins */
@mixin transition-stack($vars){
	-webkit-transition: $vars;
	   -moz-transition: $vars;
	     -o-transition: $vars;
	        transition: $vars;
}
@mixin transform-stack($vars){
	-ms-transform: $vars;
	-webkit-transform: $vars;
	        transform: $vars;
}
@mixin input-placeholder-focus($font_color){
input:focus::-webkit-input-placeholder{color:$font_color;}
input:focus:-moz-input-placeholder{color:$font_color;}
input:focus::-moz-input-placeholder{color:$font_color;}
input:focus::-ms-input-placeholder{color:$font_color;}
}
@mixin textarea-placeholder-focus($font_color){
textarea:focus::-webkit-input-placeholder{color:$font_color;}
textarea:focus:-moz-input-placeholder{color:$font_color;}
textarea:focus::-moz-input-placeholder{color:$font_color;}
textarea:focus::-ms-input-placeholder{color:$font_color;}
}
@mixin input-placeholder($font_color, $transform){
::-webkit-input-placeholder{color:$font_color;letter-spacing: .04em;text-transform: $transform;}
:-ms-input-placeholder{color:$font_color;letter-spacing: .04em;text-transform: $transform;}
::-moz-placeholder{color:$font_color;letter-spacing: .04em;text-transform: $transform;}
:-moz-placeholder{color:$font_color;letter-spacing: .04em;text-transform: $transform;}
}
@mixin input-placeholder-contact($font_color, $transform){
::-webkit-input-placeholder{color:$font_color;letter-spacing: .04em;text-transform: $transform; font-size: 13px; font-weight: 400; font-family: 'Montserrat', sans-serif;}
:-ms-input-placeholder{color:$font_color;letter-spacing: .04em;text-transform: $transform; font-size: 13px; font-weight: 400;}
::-moz-placeholder{color:$font_color;letter-spacing: .04em;text-transform: $transform; font-size: 13px; font-weight: 400; opacity: 1;}
:-moz-placeholder{color:$font_color;letter-spacing: .04em;text-transform: $transform; font-size: 13px; font-weight: 400; opacity: 1;}
}


/* partials */
@import 'reset';
@import 'general';
@import 'header';
@import 'accordian';
@import 'main';
@import 'forms';
@import 'xray';
@import 'xray2';
@import 'testimonials';
@import 'footer';
@import 'mobileNav';
@import 'responsive';
