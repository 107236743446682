// Fonts


// Accordion Height
$a-height: 250px;

// Position text along bottom
$text-offset: $a-height - 90;




.accordion {

  width:100%;
/*  max-width:1080px;*/
  height:$a-height;
  overflow:hidden;
  margin:-4px auto;
  
  h1 {
  
  text-align:center;
  font-family:Montserrat,sans-serif;
  color:#333;

}

  ul {

    width:100%;
    display:table;
    table-layout:fixed;
    margin:0;
    padding:0;

    li {

      display:table-cell;
      vertical-align:bottom;
      position: relative;
      width:25%; 
      height:$a-height;
	  background:#000;
      background-repeat:no-repeat;
      background-position:center center;

      transition:all 500ms ease;

      div {
        
        display:block;
        overflow:hidden;
        width:100%;

        a {
          
          display:block;
          height:$a-height;
          width:100%;

          position:relative;
          z-index:3;
          vertical-align:bottom;
          padding:15px 20px;
          box-sizing:border-box;
          color:#000;
          text-decoration:none;
          font-family:Open Sans, sans-serif;
          
          transition:all 200ms ease;

          * {

            opacity:0;
            margin:0;
            width:100%;
            text-overflow:ellipsis;
            position:relative;
            z-index:5;
            
            white-space:nowrap;
            overflow:hidden;
            
            -webkit-transform:translateX(-20px);
            transform:translateX(-20px);

            -webkit-transition:all 400ms ease;
            transition:all 400ms ease;

          }

          h2 {

            font-family:Montserrat,sans-serif;
            text-overflow:clip;
            font-size:24px;
            text-transform:uppercase;
            margin-bottom:2px;
            
            top:$text-offset;

          }
          
          p {
            
            top:$text-offset;
            font-size:13.5px;
            
          }

        }

      }

    }
    
    // Background images

	li:nth-child(1) {  }
    li:nth-child(2) {  }
    li:nth-child(3) {  }
    li:nth-child(4) {  }



    &:hover li { width:8%; }

    &:hover li:hover {

      width:15%;

      a {
        
        background:rgba(0,0,0,.2);
        
        * {
          
          opacity:1;
          -webkit-transform:translateX(0);
          transform:translateX(0);
        
        }
      
      }

    }

  }

}




@media screen and (max-width: 750px){
	.accordion {

  width:100%;
/*  max-width:1080px;*/
  height:200px;
  overflow:hidden;
  margin:-4px auto;
  
  h1 {
  
  text-align:center;
  font-family:Montserrat,sans-serif;
  color:#333;

}

  ul {

    width:100%;
    display:table;
    table-layout:fixed;
    margin:0;
    padding:0;

    li {

      display:table-cell;
      vertical-align:bottom;
      position: relative;
      width:27%; 
      height:200px;
	  background:#000;
      background-repeat:no-repeat;
      background-position:center center;
	  background-size: cover;

      transition:all 500ms ease;

      div {
        
        display:block;
        overflow:hidden;
        width:100%;

        a {
          
          display:block;
          height:200px;
          width:100%;

          position:relative;
          z-index:3;
          vertical-align:bottom;
          padding:15px 20px;
          box-sizing:border-box;
          color:#000;
          text-decoration:none;
          font-family:Open Sans, sans-serif;
          
          transition:all 200ms ease;

          * {

            opacity:0;
            margin:0;
            width:100%;
            text-overflow:ellipsis;
            position:relative;
            z-index:5;
            
            white-space:nowrap;
            overflow:hidden;
            
            -webkit-transform:translateX(-20px);
            transform:translateX(-20px);

            -webkit-transition:all 400ms ease;
            transition:all 400ms ease;

          }

          h2 {

            font-family:Montserrat,sans-serif;
            text-overflow:clip;
            font-size:24px;
            text-transform:uppercase;
            margin-bottom:2px;
            
            top:$text-offset;

          }
          
          p {
            
            top:$text-offset;
            font-size:13.5px;
            
          }

        }

      }

    }
    
    // Background images

	li:nth-child(1) {  }
    li:nth-child(2) {  }
    li:nth-child(3) {  }
    li:nth-child(4) {  }



    &:hover li { width:8%; }

    &:hover li:hover {

      width:15%;

      a {
        
        background:rgba(0,0,0,.2);
        
        * {
          
          opacity:1;
          -webkit-transform:translateX(0);
          transform:translateX(0);
        
        }
      
      }

    }

  }

}
}





@media screen and (max-width: 600px){
	.accordion {

  width:100%;
/*  max-width:1080px;*/
  height:100px;
  overflow:hidden;
  margin:-4px auto;
  
  h1 {
  
  text-align:center;
  font-family:Montserrat,sans-serif;
  color:#333;

}

  ul {

    width:100%;
    display:table;
    table-layout:fixed;
    margin:0;
    padding:0;

    li {

      display:table-cell;
      vertical-align:bottom;
      position: relative;
      width:27%; 
      height:100px;
	  background:#000;
      background-repeat:no-repeat;
      background-position:center center;
	  background-size: cover;

      transition:all 500ms ease;

      div {
        
        display:block;
        overflow:hidden;
        width:100%;

        a {
          
          display:block;
          height:100px;
          width:100%;

          position:relative;
          z-index:3;
          vertical-align:bottom;
          padding:15px 20px;
          box-sizing:border-box;
          color:#000;
          text-decoration:none;
          font-family:Open Sans, sans-serif;
          
          transition:all 200ms ease;

          * {

            opacity:0;
            margin:0;
            width:100%;
            text-overflow:ellipsis;
            position:relative;
            z-index:5;
            
            white-space:nowrap;
            overflow:hidden;
            
            -webkit-transform:translateX(-20px);
            transform:translateX(-20px);

            -webkit-transition:all 400ms ease;
            transition:all 400ms ease;

          }

          h2 {

            font-family:Montserrat,sans-serif;
            text-overflow:clip;
            font-size:24px;
            text-transform:uppercase;
            margin-bottom:2px;
            
            top:$text-offset;

          }
          
          p {
            
            top:$text-offset;
            font-size:13.5px;
            
          }

        }

      }

    }
    
    // Background images

	li:nth-child(1) {  }
    li:nth-child(2) {  }
    li:nth-child(3) {  }
    li:nth-child(4) {  }



    &:hover li { width:8%; }

    &:hover li:hover {

      width:15%;

      a {
        
        background:rgba(0,0,0,.2);
        
        * {
          
          opacity:1;
          -webkit-transform:translateX(0);
          transform:translateX(0);
        
        }
      
      }

    }

  }

}
}
	
	
// Stack items
/*@media screen and (max-width: 600px) {
  
  // IE gets fussy if this isn't here
  body { margin:0; }
  
  .accordion {
    
    height:auto;
    
    ul,ul:hover {
      
      li,li:hover {
        
        position:relative;
        display:table;
        table-layout:fixed;
        width:100%;
        
        -webkit-transition:none;
        transition:none;
        
      }
      
    }
    
  }

}

.about {
  
  text-align:center;
  font-family:'Open Sans', sans-serif;
  font-size:12px;
  color:#666;
  
  a {
    
    color:blue;
    text-decoration:none;
    
    &:hover { text-decoration:underline; }
    
  }
  
}*/