/* -------------------------------------------------------------------------- */
/* Forms */
/* -------------------------------------------------------------------------- */
#newsletter-form {
	margin-bottom: 12px;
	position: relative;
}
.form-intro {
	font-size: 20px;
	color: $color_dk-grey-1;
	margin-bottom: 12px;
	line-height: 30px;
}
form input, form select {
	width: 60%;
	box-sizing: border-box;
	border: none;
	background-color: $color_lt-grey-1;
	color: $color_dk-grey-2;
	padding: 8px 12px;
	font-size: 16px;
	font-family: $font-form;
	margin-bottom: 10px;
}
#newsletter-form form input {
	width: 28%;
	background-color: $color_lt-grey-2;
	color: $color_white;
	border-right: solid 2px #58595b;
	padding: 14px 14px;
	margin-bottom: 0;
	@include transition-stack(all 200ms ease);
}
#newsletter-form form input:focus {
	background-color: $color_dk-grey-1;
}
form textarea {
	width: 80%;
	box-sizing: border-box;
	border: none;
	background-color: $color_lt-grey-1;
	color: $color_dk-grey-2;
	padding: 8px 12px;
	font-size: 16px;
	font-family: $font-body;
	margin-bottom: 4px;
	min-height: 180px;
}
form select {
	position: relative;
}
form input[type="submit"]{
	display: block;
	box-sizing: border-box;
	border: solid 2px $color_teal;
	font-size: 14px;
	font-family: $font-form;
	font-weight: 700;
	text-transform: uppercase;
	text-align: center;
	width: auto;
	padding: 7px 35px;
	background-color: $color_white;
	@include transition-stack(all 200ms ease);
}
#newsletter-form form input[type="submit"] {
	display: inline-block;
	width: 16%;
	background-color: $color_blue;
	border: 2px solid $color_black;
	padding: 13px 0;
	font-family: $font-form;
	font-size: 16px;
	font-weight: 400;
	text-transform: upercase;
}
form input[type="submit"]:hover {
	background-color: $color_teal;
	color: $color_white;

}
#newsletter-form form input[type="submit"]:hover {
	background-color: $color_teal;
	color: $color_white;
	border: 2px solid $color_black;

}
.thank {
	visibility: hidden;
	opacity:0;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	font-size: 20px;
	line-height: 30px;
	text-align: center;
	padding: 100px 14px 12px;
	border: solid 2px $color_teal;
	background-color: $color_white;
	color: $color_lt-grey-2;
	-webkit-transition: visibility 0s linear 0.2s,opacity 0.2s linear;
	        transition: visibility 0s linear 0.2s,opacity 0.2s linear;
}
#newsletter-form .thank {
	color: $color_dk-grey-2;
	background-color: $color_lt-grey-2;
	padding: 0;
	font-size: 16px;
	line-height: 47px;
	letter-spacing: 0.8px;
}
#newsletter-form .thank div {
	width: 76%;
	float: left;
	border-right: solid 2px $color_white;
	box-sizing: border-box;
}
.show-thank .thank {
	visibility: visible;
	opacity:1;
	transition-delay:0s;
}
.thank-close {
	display: inline-block;
	box-sizing: border-box;
	border: none;
	font-family: $font-body;
	font-size: 14px;
	text-transform: uppercase;
	font-weight: 700;
	letter-spacing: 0.15em;
	margin: 24px auto 0;
	padding: 4px 42px;
	border: solid 2px $color_teal; 
	background-color: $color_white;
	color: $color_dk-grey-1;
	@include transition-stack(all 200ms ease);
}
.thank-close:hover {
	text-decoration: none;
	background-color: $color_teal;
	color: $color_white;
}

#newsletter-form .thank-close {
	display: block;
	width: 24%;
	float: right;
	margin: 0;
	padding: 0;
	background-color: $color_teal;
	color: $color_white;
	@include transition-stack(all 200ms ease);
}
#newsletter-form .thank-close:hover {
	background-color: $color_dk-grey-1;
	color: $color_white;
}
form input::-webkit-input-placeholder {font-family: $font-form; font-weight: 700; font-size: 15px; line-height: 20px; letter-spacing: .04em; color: $color_dk-grey-1; text-transform: uppercase;}
form input:-moz-placeholder {font-family: $font-form; font-weight: 700; font-size: 15px; line-height: 20px; letter-spacing: .04em; color: $color_dk-grey-1; text-transform: uppercase;}/* Firefox 18- */
form input::-moz-placeholder {font-family: $font-form; font-weight: 700; font-size: 15px; line-height: 20px; letter-spacing: .04em; color: $color_dk-grey-1; text-transform: uppercase;}/* Firefox 19+ */
form input:-ms-input-placeholder {font-family: $font-form; font-weight: 700; font-size: 15px; line-height: 20px; letter-spacing: .04em; color: $color_dk-grey-1; text-transform: uppercase;}
form textarea::-webkit-input-placeholder {font-family: $font-form; font-weight: 700; font-size: 15px; line-height: 20px; letter-spacing: .04em; color: $color_dk-grey-1; text-transform: uppercase;}
form textarea:-moz-placeholder {font-family: $font-form; font-weight: 700; font-size: 15px; line-height: 20px; letter-spacing: .04em; color: $color_dk-grey-1; text-transform: uppercase;}/* Firefox 18- */
form textarea::-moz-placeholder {font-family: $font-form; font-weight: 700; font-size: 15px; line-height: 20px; letter-spacing: .04em; color: $color_dk-grey-1; text-transform: uppercase;}/* Firefox 19+ */
form textarea:-ms-input-placeholder {font-family: $font-form; font-weight: 700; font-size: 15px; line-height: 20px; letter-spacing: .04em; color: $color_dk-grey-1; text-transform: uppercase;}
#newsletter-form form input::-webkit-input-placeholder {font-family: $font-form; font-weight: 400; font-size: 16px; line-height: 20px; letter-spacing: .04em; color: $color_white; text-transform: none;}
#newsletter-form form input:-moz-placeholder {font-family: $font-form; font-weight: 400; font-size: 16px; line-height: 20px; letter-spacing: .04em; color: $color_white; text-transform: none;}/* Firefox 18- */
#newsletter-form form input::-moz-placeholder {font-family: $font-form; font-weight: 400; font-size: 16px; line-height: 20px; letter-spacing: .04em; color: $color_white; text-transform: none;}/* Firefox 19+ */
#newsletter-form form input:-ms-input-placeholder {font-family: $font-form; font-weight: 400; font-size: 16px; line-height: 20px; letter-spacing: .04em; color: $color_white; text-transform: none;}

.contact-form{
	form{
		margin-bottom:36px;
		input, textarea, select{
			background-color:$color_white;
			border:1px solid $color_dk-grey-2;
			margin-bottom: 18px;
			font-size: 13px;
			font-weight:400;
		}
		input, select{
			width:48%;
			margin-right: 2%;
		}
		textarea{
			width:98%;
			background-color:$color_white;
			border:1px solid $color_dk-grey-2;

		}
		input.field.email{width: 98%;}
		select{
			overflow:hidden;
			background:url('images/arrow-down.png')no-repeat 95% $color_white;
			border:1px solid $color_dk-grey-2;
			-webkit-appearance: none;
			-webkit-appearance: none;
			-moz-appearance: none;
			-ms-appearance: none;
			-o-appearance: none;
			appearance: none;
			appearance:none;
			color:$color_dk-grey-2;
			text-transform: uppercase;
			font-weight:400;
			font-family: $font-form;
			letter-spacing: .04em;
		}


		input[type=submit]{
			width:148px;
			border:2px solid $color_black;
			background: $color_blue;
			color: $color_white;
			  font-size: 15px;

		}
		input[type=submit]:hover{background-color:$color_teal; border:2px solid $color_black;}
		@include input-placeholder-focus($color_white);
		@include textarea-placeholder-focus($color_white);
		@include input-placeholder-contact($color_dk-grey-2, uppercase);
		input:focus, textarea:focus, select:focus {
			color:$color_white;
			background-color: $color_lt-grey-2;
			outline: 0;
		}

	}
}
.buying .contact-form, .selling .contact-form{width:330px;}