/* -------------------------------------------------------------------------- */
/* General Styling */
/* -------------------------------------------------------------------------- */
.cc-grey-0 {color:$color_lt-grey-1;}
.cc-grey-1 {color:$color_lt-grey-1;}
.cc-grey-2 {color:$color_lt-grey-2;}
.cc-grey-3 {color:$color_dk-grey-1;}
.cc-grey-4 {color:$color_dk-grey-1;}
.cc-grey-5 {color:$color_dk-grey-2;}
.cc-blue {color:$color_green}
.fw-300 {font-weight:300;}
.fw-400 {font-weight:400;}
.fw-500 {font-weight:500;}
.fw-600 {font-weight:600;}
.fw-700 {font-weight:700;}
.fw-800 {font-weight:800;}
.tt-upper {text-transform:uppercase;}
.tt-lower {text-transform:lowercase;}
.tt-none {text-transform:none;}
.tt-cap {text-transform:capitalize;}
.ital {font-style:italic;}
.bold {font-weight:700;}
.clearfix {clear: both;}
.show-on-mobile {display: none;}
.show-on-tablet {display: none;}
svg {width:100%; height:100%;}
.emph{color:$color_black; font-weight: 800;}
/* phone click supression */
.phone {position: relative;}
.phone .phoneLink {position: absolute;top: 0;left: 0;width: 100%;height: 0;}
/* general em, p, a, li, h1-6, etc */
body { font-family: $font-body; font-weight: 400; font-size: 19px; line-height: 30px; letter-spacing: .01em; width:100%;   overflow-x: hidden ; overflow-y: auto;}
em { font-family: $font-emph; font-weight: 700; font-size: 15px; line-height: 21px; letter-spacing: .06em; color: $color-em; margin-bottom: 6px; }
strong { font-weight: 800; color: $color-strong; margin-bottom: 7px; }
li { color: $color-li; margin-bottom: 10px; font-family: $font-body; font-size:20px; line-height: 38px; letter-spacing: .01em; font-weight: 300;}
li strong { font-weight: 700; color: $color-li-strong; margin-bottom: 7px; }
p { color: $color-p; margin-bottom: 18px; letter-spacing:.01em; line-height:31px; font-family: $font-paragraph;}
a { color: $color-a; margin-bottom: 7px; }
.home h1, .home .h1-like { font-family: $font-heading; font-weight: 700; font-size: 50px; line-height: 51px; letter-spacing: .08em; color: $color-h1; margin-bottom: 7px; text-transform: uppercase; }
h1, .h1-like { font-family: $font-heading; font-size: 47px; line-height: 59px; letter-spacing: .025em; color: $color-h1; margin-bottom: 12px; text-transform: initial; }
h2, .h2-like { font-family: $font-heading; font-weight: 400; font-size: 30px; line-height: 40px; letter-spacing: .02em; color: $color-h2; margin-bottom: 17px; text-transform: initial; }
h3, .h3-like { font-family: $font-heading2; text-transform: uppercase; font-weight: 400; font-size: 15px; line-height: 27px; letter-spacing: .1em; color: $color-h3; margin-bottom: 8px; margin-top: 8px; }
h4, .h4-like { font-family: $font-body; font-weight: 400; font-size: 20px; line-height: 33px; letter-spacing: .01em; color: $color-h4; margin-bottom: 7px; }
h5 { font-family: $font-body; font-weight: 400; font-size: 42px; line-height: 47px; letter-spacing: .01em; color: $color-h5; margin-bottom: 8px; }
.more-symbol {display:inline;}
.show .more-symbol {display:none;}
.col-33 {
  width: 33.2%;
  box-sizing: border-box;
  float:left;
}
.col-66 {
  width: 66.8%;
  box-sizing: border-box;
  float:left;
}



@media screen and (max-width: 750px){
	h1, .h1-like { font-family: $font-heading; font-size: 42px; line-height: 59px; letter-spacing: .025em; color: $color-h1; margin-bottom: 12px; text-transform: initial; }
	h2, .h2-like { font-family: $font-heading; font-weight: 400; font-size: 27px; line-height: 36px; letter-spacing: .0em; color: $color-h2; margin-bottom: 17px; text-transform: initial; }
	h3, .h3-like { font-family: $font-heading2; font-weight: 400; font-size: 15px; line-height: 22px; letter-spacing: .1em; color: $color-h3; margin-bottom: 0px; margin-top: 8px; }
}



@media screen and (max-width: 600px){
	p { font-size: 15px; line-height: 25px; color: $color-p; margin-bottom: 18px; letter-spacing:.01em; font-family: $font-paragraph;}
	h1, .h1-like { font-family: $font-heading; font-size: 32px; line-height: 39px; letter-spacing: .025em; color: $color-h1; margin-bottom: 8px; text-transform: initial; }
	h2, .h2-like { font-family: $font-heading; font-weight: 400; font-size: 24px; line-height: 32px; letter-spacing: .0em; color: $color-h2; margin-bottom: 17px; text-transform: initial; margin-top: 10px; }
	h3, .h3-like { font-family: $font-heading2; font-weight: 400; font-size: 14px; line-height: 21px; letter-spacing: .1em; color: $color-h3; margin-bottom: 0px; margin-top: 8px; }
	li { color: $color-li; margin-bottom: 7px; font-family: $font-body; font-size:15px; line-height: 25px; letter-spacing: .01em; font-weight: 300;}
}