#head-cta{
    position: fixed;
    top:0;
    left:0;
    right:0;
    text-align: center;
    z-index:10;
    background-color:#fff;
}
#nav-cta{
    position: fixed;
    top:0;
    left:0;
    right:0;
    z-index:10;
    background:$color_black;
}
#top-header{ height:106px; }
#logo{
    width:342px;
    position: absolute;
	left:64px;
	top:-21px;
	-webkit-animation-duration: 1s; 
    animation-duration: 1s; 
    -webkit-animation-fill-mode: both; 
    animation-fill-mode: both;
	-webkit-animation-name: bounceInLeft; 
    animation-name: bounceInLeft; 
} 
#contact-header{
    width:295px;
    box-sizing: border-box;
	position: absolute;
	right:46px;
	bottom:22px;
}
.phone a{
    color:$color_blue;
    font-size:33px;
	font-weight:700;
    float:right;
    letter-spacing: .04em;
    text-decoration:none;
}
.contact-links{
    float:right;
    display:inline-block;
    letter-spacing: .04em;
}
nav{ 
	background:$color_black; 
	position:relative;
	ul{
		margin-left:5%;
	}
	li{
		float:left;
		height:40px;
		color:$color_white;
		box-sizing:border-box;
		border-top:2px solid #000;
		border-bottom:2px solid #000;
		margin-bottom: 0px;
		font-size: 14px;
		line-height: 17px;
		letter-spacing: .065em;
	}
	ul ul{
		position: absolute;
		width:250px;
		max-height:0;
		overflow:hidden;
		margin:0;
		top: 40px;
		transition: all .2s ease;
		-webkit-transition: all .2s ease;
	}
	ul ul li{
		background:$color_teal;
		border-bottom:1px solid #fff;
		float:none;
		height:31px;
		line-height:31px;
		box-sizing: initial;
		border-top:0;
	}
	ul ul li:hover{
		border-top:0;
		border-bottom:1px solid #fff;
	}
	ul ul li a{
		color:#fff;
		font-size:13px;
		font-family: 'Montserrat', sans-serif;
		display:block;
		padding:0 11px;
		display:block;
		background:$color_teal;
		height:31px;
		line-height:31px;
		text-transform: none;
		letter-spacing:0;
	}
	li.active ul li a, li:hover ul li a{
		color:#fff;
	}
	li.current a {
			color: $color_teal;
		}
	ul ul li:hover a{
		background:#fff;
		color:$color_teal;
	}
	ul li:hover ul{
		max-height:200px;
	}
	a, h6{
		color:$color_white;
		text-decoration:none;
		font-weight: 400;
		font-size:14px;
		letter-spacing: .08em;
		padding:11px 11px;
		text-transform: uppercase;
		line-height: 37px;
	}
	a:hover, h6:hover a{
		color: $color_teal;
	}
	li.active, li:hover a{ 
		color: $color_teal;
		transition: all .2s ease;
		-webkit-transition: all .2s ease;
	}
	li.active, li:hover{
/*		border-top:2px solid $color_teal;
		border-bottom:2px solid $color_teal;*/
	}
}
.header{
    text-transform: uppercase;
    font-size:40px;
    font-family: "futura-pt",sans-serif;
    font-weight:300;
    line-height:55px;
    letter-spacing: .13em;
}
.nav-facebook{
	color:#fff;
	fill:#fff;
	position: absolute;
	right:46px;
	padding:0;
	transition: all .2s ease;
	-webkit-transition: all .2s ease;
	svg{
		display:inline-block;
		width:27px;
		height:27px;
		vertical-align: middle;
		margin:-3px 0 0 5px;
	}
}
.nav-facebook:hover{
	color:$color_white;
	fill:$color_white;
	transition: all .2s ease;
	-webkit-transition: all .2s ease;
} 
.facebook, .twitter, .googleplus, .instagram{
	width:23px; height:23px;
	fill:#fff;
	display:inline-block;
	padding:0 2px;
}
.yelp{
	width:53px; height:23px;
	fill:#fff;
	display:inline-block;
	padding:0 2px;
}
.instagram:hover, .facebook:hover, .twitter:hover, .googleplus:hover{
	fill:$color_teal;
}
.nav-social{
	position: absolute;
	right:40px;
	top:7px;
}
.logo-small{
	background:url('images/coastal-chiro-logo.png')center no-repeat;
	background-size:contain;
	width:37px; height:24px;
	position: absolute;
	  margin: 8px 0 0 0px;
	display:block;
	  left: 2%;

}
.logo-mobile{
	background:url('images/mobile-logo.png')center no-repeat;
	background-size:contain;
	  width: 120px;
  height: 40px;
	position: absolute;
	  margin: 5px 0 0 0px;
	display:block;

}
#home .logo-small{
	background:url('images/coastal-chiro-logo.png')center no-repeat;
	background-size:contain;
	width:37px; height:24px;
	position: absolute;
	  margin: 8px 0 0 0px;
	display:block;
	opacity: 0; 
	left: 2%;
	transform: translateX(-200px);
    -webkit-transform: translateX(-200px); 
	-ms-transform: translateX(-200px);
	transition: all .5s ease;
	-webkit-transition: all .5s ease;
}
#home .logo-small.animate{
	opacity:1;
	-webkit-transform: translateX(0);
	-ms-transform: translateX(0);
	transform: translateX(0);
	
}
.mobile-logo-new{
	display: none;
}
