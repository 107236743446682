/* -------------------------------------------------------------------------- */
/* Main */
/* -------------------------------------------------------------------------- */
main{
	padding-top:10px;
}
section.teal{background: $color_teal;
	 p.testimonials{color: $color_white;}
}
section.white{background: $color_white;
	 p.testimonials{color: $color_dk-grey-1;}
}
section.gray{background: $color_dk-grey-1;
	 p.testimonials{color: $color_white;}
} 
.sectionContainer {
	position: relative;
    max-width: 1000px;
    margin: 0 auto;
	  padding: 57px 150px 35px 50px;
/*    padding: 57px 295px 35px 50px;*/
	a:hover{text-decoration:underline;}
	img.matt{ float: right; width: 45%;  padding-left: 15px;}
	ul{margin-bottom: 17px;}
	li{
		padding-left: 1em; 
		text-indent: -.7em;
		

	}
	li:before {
		content: "• ";
		color: $color_grayDark; 
	}
	p.education1{margin-bottom: 0px;}
	p.testimonials{
		text-align: left;
	font-style:italic;
	letter-spacing: .06em;
	}
	
	.question:before{
				content:'';
				background:url('images/plus.png')center no-repeat;
				width:13px; height:13px;
				position:absolute;
				margin:10px 0 0 -20px;
			}
			.question.show:before{
				content:'';
				background:url('images/minus.png')center no-repeat;
				width:13px; height:13px;
				position:absolute;
				margin:10px 0 0 -20px;
				
			}
			.answer{
				max-height:0;
				overflow:hidden;
				-webkit-transition: all .2s ease;
				transition: all .2s ease;
				font-weight: 300;
				margin-left: 6%;
				line-height: 32px;
				margin-top: -15px;

			}
			.answer.show{
				max-height:1000px;
				-webkit-transition: all .2s ease;
				transition: all .2s ease;
			}
			.question{
				cursor:pointer;
				  margin-bottom: 24px;
				p{ 
					font-size: 20px;
					margin-bottom:15px; 
				}
			}
			.faq{
				margin-left: 20px;
			}
			
}
.home .sectionContainer, .home-search .sectionContainer {
    padding: 35px 50px;
	img{max-width:100%;}
}
.blog .sectionContainer,
.post .sectionContainer {
    max-width: 600px;
    margin: 0 auto;
    padding: 35px 25px;
	text-align: left;
}

.container {
	max-width:1000px;
	margin:0 auto;
	position: relative;
	padding:0 50px;
}
.container.wide {
	max-width:1045px;
}


/*.slide-text-position{
	position: relative;
	height: 408px;
	margin-top: 140px;
}*/

#slideshow {
	height: 611px;
	position: relative; 
	overflow: hidden;
	text-align: left;
}
#slides { 
	position:absolute;
	top:0;
	left:0;
	right:0;
	bottom:0;
	list-style-type: none;
}
#slides .slide {
	position:absolute;
	top:0;
	left:0;
	right:0;
	bottom:0;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	opacity: 0;
	@include transition-stack(all 800ms ease);
}
#slides .slide.active {
	opacity: 1;
	@include transition-stack(all 800ms ease);
}
#slides .slide .slideOverlay {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #000;
	opacity: 0;
}
#slideshow h1 {
	position: absolute;
	top: 42%;
	left: 50%;
	margin-left: -450px;
	font-size: 50px;
	color: $color_white;
	line-height: 55px;
	text-shadow: 10px 10px 22px #000;
	text-shadow: 10px 10px 22px rgba(0,0,0,.75);
}
#slideshow .home-h1-subtitle {
	position: absolute;
	top: 240px;
	left: 50%;
	margin-left: -450px;
	font-family: 'Muli', sans-serif;
	font-size: 34px;
	color: $color_white;
	line-height: 32px;
	text-shadow: 10px 10px 22px #000;
	text-shadow: 10px 10px 22px rgba(0,0,0,.75);
	max-width:475px;
}
#slideshow .get-started {
	position: absolute;
	
	height: 32px;
	width: 148px;
	border: solid 2px $color_white;
	background-color: $color_green;
	color: $color_white;

	font-family: $font-heading;
	font-weight: 700;
	font-size: 15px;
	line-height: 30px;
	text-align: center;
	text-transform: uppercase;
	letter-spacing: .1em;
	display: block;
	margin-top: 30px;
}
#slideshow .get-started:hover {
	border: solid 2px $color_white;
	background-color: $color_teal;
	color: $color_white;
}
#slide-nav { 
	position: absolute; 
	bottom: 28px;
	left: 50%;
	margin-left: -450px;
}
#slide-nav .radio-wrap {
	background-color: transparent;
	background-color: rgba(0,0,0,0.25);
	border: solid 2px $color_white;
	border-radius: 100%;
	margin-right: 7px;
	width: 15px;
	height: 15px;
	cursor: pointer;
	display: inline-block;
	box-sizing: border-box;
	@include transition-stack(all 200ms ease);
}
#slide-nav .radio-wrap:hover {
	background: $color_teal;
}
#slide-nav .radio-wrap.checked {
	background: $color_white;
	border: solid 2px $color_white;
}
#slide-nav .radio-wrap.checked:hover {
	background: $color_teal;
}
.slider-logo{
	display: inline-block;
	width: 488px;
	height: 175px;
	margin-top: 7px;
	background:url('images/coastal-slider-logo.png')center no-repeat;
	background-size:contain;
	position: absolute;
	left: 0;
	top: -184px;

}
.slider-pic{
	display: inline-block;
	width: 169px;
	height: 169px;
	margin-top: 7px;
	background:url('images/MattCircle.png')no-repeat;
	background-size:contain;
	position: absolute;
	left: 0;
	top: 43px;
	

}






.nav-icons {
	text-align: center;
	margin-bottom: 0px;
}
.home .nav-icons {
	margin-bottom: 50px;
}
.nav-icon {
	height: 110px;
	width: 82px;
	display: inline-block;
	margin: 0 64px 0 0;
}
.nav-icon:last-of-type {
	margin: 0;
}
.nav-icon svg {
	height: 70px;
	width: 70px;
	padding: 0;
	margin: 0 6px 10px;
	display: block;
	fill: $color_lt-grey-1;
	@include transition-stack(all 200ms ease);
}
.nav-icon:hover svg {
	fill: $color_green;
}
.nav-icon-text {
	display: block;
	height: 30px;
	width: 82px;
	text-align: center;
	line-height: 30px;
	padding: 0;
	margin: 0;
	font-family: $font-heading;
	color: $color_dk-grey-1;
	letter-spacing: .14em;
	text-transform: uppercase;
	font-size: 13px;
}


.row {
	text-align: left;
	margin-bottom: 20px;
}
.row:last-of-type {
	margin-bottom: 40px;
}
.col-left, .col-right {
	display: inline-block;
	text-align: left;
	height: 100%;
	vertical-align: top;
}
.col-right{
	position: relative;
}
.col-left .last {
	margin-bottom: 27px;
}
.col-left h2 {
	line-height: 28px;
}
.rowTitle .col-left {
	width: 48.38709%;
	margin-right: 3.22581%;
}
.rowTitle .col-right {
	text-align: center;
	width: 45.37634%;
}
.rowContent .col-left {
	width: 25.80645%;
	margin-right: 2.15054%;
}
.rowContent .col-right {
	width: 66.45161%;
}
.rowContent ul {
	padding-left: 40px;
}
.rowContent li {
	list-style: disc;
	list-style-position: inside;
	padding-left: 22px;
	text-indent: -22px;
}

.about .rowContent .col-left {
	width: 29.03226%;
	margin-right: 2.15054%;
}
.about .rowContent .col-right {
	width: 63.76344%;
}

.buylist .rowContent {
	position: relative;
	padding-left: 150px;
}
.buylist .rowContent .col-left {
	position: absolute;
	top: 0;
	left: 0;
}
.buylist .rowContent .col-right {
	width: 100%;
}
.buylist .rowContent li {
	list-style: none;
	padding-left: 0;
	text-indent: 0;
	display: inline-block;
	width: 90%;
}
.buylist .rowContent ul {
	padding-left: 0;
	-webkit-column-count: 3;
	   -moz-column-count: 3;
	        column-count: 3;
}

.home .row1 .col-left {
	width: 56.66666%;
	margin-right: 2.22222%;
}
.home .row1 .col-left p {
	margin-bottom: 10px;
}
.home .row1 .col-right {
	text-align: center;
	width: 41.11111%;
}
.home .row2 {
	height: 388px;
}
.home .row2 .col-left {
	text-align: center;
	width: 38.88888%;
	margin-right: 2.22222%;

}
.home .row2 .col-right {
	width: 58.88888%;
}
.more-text{
	max-height:0;
	overflow:hidden;
	@include transition-stack(all .2s ease);
}
.more-text.shown{
	max-height:1500px;
	@include transition-stack(all .2s ease);
}




.home-learn-p{
	opacity: 0; 
	transform: translateX(-200px);
    -webkit-transform: translateX(-200px); 
	-ms-transform: translateX(-200px);
	transition: all .5s ease;
	-webkit-transition: all .5s ease;
}

.home-learn {
	text-align: left;
	
	.more, .shown-read-more {
		font-family: $font-heading;
		font-size: 15px;
		text-transform: uppercase;
		font-weight: 700;
		letter-spacing: 0.1em;
		color:$color_green;
		cursor:pointer;
	}
}

.home-consign .imageWrap {
	padding-top: 53.90071%;
	position: relative;
}
.home-consign .image {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-image: url('images/spine.jpg');
	background-position: center top;
	background-repeat: no-repeat;
	background-size: cover;	
}
.home-consign a {
	position: absolute;
	top: 50%;
	margin: -27px 0 0;
	display: block;
	width: 100%;
	height: 54px;
	background-color: $color_blue;
	color: $color_white;
	line-height: 54px;
	font-family: $font-heading;
	font-size: 19px;
	text-transform: uppercase;
	font-weight: 700;
	letter-spacing: 0.1em;
	@include transition-stack(all 200ms ease);
}
.show-at-710 {
	display:none;
}
.show-at-780 {
	display:none;
}
.home-consult {
	background-image:url('images/MattHomepage.jpg');
	color: $color_white;
	width: 100%;
	height: 388px;
	position: relative;
	  background-size: cover;
	  	opacity: 0; 
	-webkit-animation-duration: .5s; 
    animation-duration: .5s; 
	transform: translateX(-200px);
    -webkit-transform: translateX(-200px); 
	-ms-transform: translateX(-200px);
	transition: all .5s ease;
	-webkit-transition: all .5s ease;
}
.home-appraise{
		  	opacity: 0; 
	-webkit-animation-duration: .5s; 
    animation-duration: .5s; 
	transform: translateX(200px);
    -webkit-transform: translateX(200px); 
	-ms-transform: translateX(200px);
	transition: all .5s ease;
	-webkit-transition: all .5s ease;
}
.home-consult.animate,
.home-learn-p.animate,
.home-consign.animate,
.home-appraise.animate
{
	opacity:1;
	-webkit-transform: translateX(0);
	-ms-transform: translateX(0);
	transform: translateX(0);

}
.testimonial-container{
	background: $color_dk-grey-1;
	position: absolute;
	overflow: scroll;
	top: 54px;
	left: 0;
	bottom: 0;
	right: 0;
	opacity: 0;
	display: none;
	-webkit-transition: opacity 0.5s ease-in-out;
    -moz-transition: opacity 0.5s ease-in-out;
    -ms-transition: opacity 0.5s ease-in-out;
    -o-transition: opacity 0.5s ease-in-out;
    transition: opacity 0.5s ease-in-out; 
}
.testimonial-container:hover{
	opacity: 1;
}
.home-consult .title {
	height: 54px;
	background-color: $color_blue;
	color: $color_white;
	line-height: 54px;
	position: absolute;
/*	top: 0;*/
	right: 0;
	left: 0;
	bottom: 0;
	font-family: $font-heading;
	font-size: 19px;
	text-transform: uppercase;
	font-weight: 700;
	letter-spacing: 0.1em;
}
.home-consult .copy{
	padding:84px 35px;
	text-align: left;
/*	font-style:italic;*/
	letter-spacing: .06em;
	color:$color_white;
}
.home-consult .copy{
	line-height:32px;
	position: relative;
}
.testimonial{line-height:32px;}
.cite{
	display:block;
	margin-top:13px;
	font-style: initial;
	font-weight: 700;
/*	font-size: 17px;*/
	text-transform: uppercase;
	letter-spacing: .1em;
	span{text-transform: initial; letter-spacing: .1em;}
}
/*.slide-test{
	position: absolute;
	top:20px;
	left:25px;
	right:25px;
	bottom:10px;
	display: none;
}*/
.hover-cover {
	display: block;
	opacity: 0;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	padding: 40px 8.57143%;
	text-align: left;
	background-color: $color_green;
	color: $color_white;
	@include transition-stack(all 200ms ease);
}
.hover-cover:hover {
	opacity: 1;
}
.hover-cover .copy {
	margin-bottom: 12px;
}
.hover-cover .copy-more {
	color: $color_dk-grey-2;
	font-family: $font-heading;
	font-size: 15px;
	text-transform: uppercase;
	font-weight: 700;
	letter-spacing: 0.1em;
}
.home-appraise .imageWrap {
	padding-top: 46.67932%;
	position: relative;
	margin-bottom: 26px;
}
.home-appraise .image {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-image: url('images/patients.jpg');
	background-position: center top;
	background-repeat: no-repeat;
	background-size: contain;	
}
.home-appraise .image:hover {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-image: url('images/patients-hover.jpg');
	background-position: center top;
	background-repeat: no-repeat;
	background-size: contain;	
}

.home-appraise .play-button{
	text-transform: uppercase;
	color:$color_dk-grey-2;
	font-size:15px;
	font-weight: 700;
	text-align: center;
	border:2px solid $color_orange;
	background-color:rgba(255,255,255,.9);
	width:146px;
	height:31px;
	line-height:29px;
	position: absolute;
	left:50%;
	margin-left:-73px;
	bottom:17%;
	cursor:pointer;
	letter-spacing: .08em;
	display: none;
}
.home-appraise .play-button:hover{
	color:$color_orange;
}
.home-appraise .title {
	color: $color_blue;
	font-family:'Montserrat', sans-serif;
	font-size: 27px;
	letter-spacing: 0.05em;
	text-align: right;
	text-transform: uppercase;
/*	margin:0 9px;*/
	display:inline-block;
	vertical-align: top;
	line-height:35px;
	float: right;
}
.home-appraise .social-connect{float:right;}
.home-appraise .subtitle {
	text-align: right;
	font-size: 19px;
	line-height: 22px;
	margin-bottom: 18px;
	font-family:'Muli', sans-serif;
}
.home-appraise a {
	display: inline-block;
	height: 35px;
	width: 135px;
	fill:$color_grayDark;
	text-transform: uppercase;
	margin:0 8px;
	 position: absolute;
  right: 0;
  color: $color_grayDark;
  border: 2px solid $color_gray;
  font-family:'Montserrat', sans-serif;
  font-size: 15px;
  line-height: 30px;
  letter-spacing: .1em;
    text-align: center;

}
.home-appraise a:hover {
	color: $color_white;
	background: $color_teal;
	text-decoration: none
}
.home-appraise a.testimonial-link{
	border: none;
	display: block;
	
	  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
  z-index: 5;
}
.home-appraise a.testimonial-link:hover{
	background: none;
	z-index: 5;
}
.meet-stephen .testimonial{background-color:$color_dk-grey-1;}
.why-stephen .testimonial, 
.practice .testimonial,
.faq .testimonial{
	background-color:$color_teal;
}
.why-stephen h2{
	margin-top: 25px;
}
.contact-pop.shown {
  display: block;
  transition: all .2s linear;
}
.contact-pop.visuallyshown {
  opacity: 1;
  @include transform-stack(translateY(0));
  transition: all .2s linear;
}
.contact-pop{
	max-width:389px;
	position:absolute;
	top:5%;
	left:50%;
	margin-left:-218px;
	background:$color_white;
	z-index:10;
	border:1px solid #000;
	transition: all .2s linear;
	display: none;
	opacity:0;
	height:600px;
	overflow:scroll;
	@include transform-stack(translateY(-50px));
	.contact-container{
		padding:0 29px;
	}
	.top-bar{
		height:34px;
		background-color:$color_orange;
	}
	.close-btn{
		color:$color_white;
		float:right;
		line-height: 34px;
		margin-right:20px;
		cursor:pointer;
	}
	.header-logo{
		max-width: 100%;
		margin-bottom:21px;
	}
	.image {
		width:73px;
		height:73px;
		position: relative;
		margin:0 0 53px 20px;
		float:left;
		background-image: url('images/tina-headshot-contact.jpg');
		background-position: center top;
		background-repeat: no-repeat;
		background-size: contain;	
	}
	.contact-text{
		float:left;
		font-size:14px;
		color:$color_dk-grey-2;
		line-height:22px;
		letter-spacing: .01em;
		width:236px;
	}
	.contact-lower-text, .contact-lower-text a{
		color:$color_dk-grey-2;
		font-family:$font-body;
		font-size:14px;
	}
	.silver-creek{
		width:200px;
		height:34px;
		margin:30px 0;
	}
}
.overlay{
	display:none;
	position: fixed;
	top:0;
	left:0;
	bottom:0;
	right:0;
	background:rgba(0,0,0,.8);
	z-index: 11;
}
.overlay.show{display:block;}
#video-pop{
	width:80%;
	position: relative;
	margin:5% 10%;
}
#video-pop .spacer{padding-top:56%;}
#video-pop iframe{
	position: absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
}
.overlay .close-btn{
	position: absolute;
	top:-30px;
	right:-30px;
}
#googleMap{height:408px;}
#googleMap img{
	-webkit-filter: grayscale(1);
	filter: grayscale(1);
}
#googleInfo{
    font-size:15px;
    color:#231f20;
    line-height:23px;
    text-align:center;
    padding:10px 0px;
	width:150px;
}
#googleInfo span{display:block;}
#googleInfo a{
    text-decoration:none;
    display:block;
    text-transform:uppercase;
    font-weight:700;
    font-size:15px;
    color:$color_teal;
    margin-top:10px;
}
.map-title{
	text-align: center;
	color: $color_white;
	background: $color_teal;
	text-transform: uppercase;
	font-family: 'Montserrat', sans-serif;
	font-size: 22px;
	line-height: 50px;
	height: 50px;
	letter-spacing: .025em;
}
.video-section{
	width: 40%;
	display: inline-block;
	margin-right: 9%;
	margin-bottom: 30px;
	  vertical-align: text-top;

}
.video-container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px; height: 0; overflow: hidden;
	margin-bottom: 15px;
}
 
.video-container iframe,
.video-container object,
.video-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.cta-side{
	position: fixed;
	top: 77px;
	right: 44px;
	background: rgba(255,255,255,0.2);
	box-shadow: 11px 12px 21px -12px #777777; 
	max-width: 213px;
	padding: 10px;
	z-index: 10;
	a.cta-appointment{
		color: $color_white;
		background: $color_black;
		display: block;
		text-align: center;
		font-family: 'Montserrat', sans-serif;
		font-size: 20px;
		line-height: 24px;
		padding: 7px;
	}
	a.cta-phone{
		color: $color_white;
		background: $color_teal;
		display: block;
		text-align: center;
		font-family: 'Montserrat', sans-serif;
		font-size: 22px;
		line-height: 26px;
		letter-spacing: .025em;
		padding: 6px;
		border: 2px solid $color_black;
		margin-bottom: 0px;
	}
}
.slideshow{ background:#393839; }
.slick-slide img{width:100%;}
.slick-prev, .slick-next{
	position: absolute;
	top:50%;
	width:45px;
	height:45px;
	border:0;
	text-indent: -9999px;
	cursor:pointer;
	margin-top:-22px;
}
.slick-prev{
	background:url('img/prev.png')center no-repeat;
	left:50px;
}
.slick-next{
	background:url('img/next.png')center no-repeat;
	right:50px;
}
.slick-prev:hover{background:url('img/prev-hover.png')center no-repeat;}
.slick-next:hover{background:url('img/next-hover.png')center no-repeat;}
.slick-slide{
	border-bottom:3px solid $color_white;
	border-left:4px solid $color_white;
	border-right:4px solid $color_white;
	box-sizing:border-box;
	margin-left:-3px;
}
.new-patient{
	background: $color_teal;
	color: $color_white;
	padding: 10px;
	
	&:hover{background: $color_black;}
}

p.special-event{
	color: $color_teal;
	font-weight: 700;
	font-size: 30px;
	color: #f26e69;
	margin-top: 30px;
}
.mobile-show{
    display: none;
}