/* -------------------------------------------------------------------------- */
/* Browser Resets */
/* -------------------------------------------------------------------------- */
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, 
pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, 
samp, small, strike, strong, sub, sup, tt, var, b, u, i, center,dl, dt, dd, ol, ul, li, 
fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, 
article, aside, canvas, details, embed,  figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary, time, mark, audio, video 
{margin: 0;padding: 0;border: 0;font-size: 100%;font: inherit;vertical-align: baseline;}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, main
footer, header, hgroup, menu, nav, section {display:block;}
body {line-height:1;height:100%;background-color:#fff;color:#000; margin: 0; padding: 0;}
ol, ul {list-style: none;}
blockquote, q {quotes: none;}
blockquote:before, blockquote:after, q:before, q:after {content:'';content:none;}
table {border-collapse:collapse;border-spacing:0;}
input {margin: 0;}
/* Special-Case resets */
article, aside, details, figcaption, figure, main
footer, header, hgroup, menu, nav, section
body, header, main, footer, div, section, article, aside, details, figcaption, 
figure, hgroup, menu, nav, span, p, a, input, textarea, select {box-sizing:border-box;}
a {text-decoration:none;color:#000;}
iframe {width: 1px;min-width: 100%;*width: 100%;}
input, select, textarea {-webkit-appearance:none;-webkit-border-radius:0;outline:none;}
input[type="radio"] {-webkit-appearance: radio;}
input[type="submit"]:hover {cursor: pointer;}
textarea, input, button, :focus {outline:none;}
